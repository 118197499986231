import { z } from 'zod';
import { isBIC, isIBAN } from 'validator';
import * as PrismaTypes from '@prisma/client';
import isValidBankAccount from '../validations/bankAccount';

export const BankAccountSchema = z.object({
	id: z.string(),
	accountNumber: z
		.string()
		.min(7)
		.max(20)
		.regex(/^(?:([0-9]{1,6})-)?([0-9]{2,10})\/([0-9]{4})$/)
		.refine((value) => isValidBankAccount(value)),
	iban: z
		.string()
		.min(5)
		.max(34)
		.refine((value) => isIBAN(value), { params: { i18n: 'iban' } })
		.nullable(),
	swift: z
		.string()
		.min(8)
		.max(11)
		.refine((value) => isBIC(value), { params: { i18n: 'swift' } })
		.nullable(),
	customerId: z.string().nullable(),
	ownerId: z.string().nullable(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date().nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.BankAccount>;

export type BankAccount = z.infer<typeof BankAccountSchema>;
