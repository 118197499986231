import { z } from 'zod';
import { InvitationSchema } from '../prisma/invitation';
import { OwnerSchema } from '../prisma/owner';
import { UserRoleSchema } from '../prisma/userRole';

export const SettingsPageData = z.object({
	employeesCount: z.number(),
	invitations: z.array(InvitationSchema),
	owner: OwnerSchema,
	params: z.object({ ownerId: z.string() }),
	ownerHasSubscription: z.boolean(),
	hasTrial: z.boolean().nullable(),
	isTrialDue: z.boolean().nullable(),
	freeTrialEndDate: z.date().nullable(),
	hasAccess: z.boolean(),
	userRoles: z.array(UserRoleSchema),
});

export type GetSettingsPageData = z.infer<typeof SettingsPageData>;

export const TrialDataCard = z.object({
	daysLeft: z.number(),
	freeTrialEndDate: z.date().nullable(),
	numberOfEmployees: z.number(),
	employeesPaidFor: z.number(),
	subscriptionActiveFrom: z.date().nullable(),
});

export type GetTrialDataCard = z.infer<typeof TrialDataCard>;

export const PremiumDataCard = z.object({
	daysLeft: z.number(),
	numberOfEmployees: z.number(),
	employeesPaidFor: z.number(),
	price: z.number(),
	subscriptionActiveFrom: z.date().nullable(),
	nextDateToPay: z.date().nullable(),
	subscriptionCancelledAt: z.date().nullable(),
	totalDaysOfCurrentPeriod: z.number(),
});

export type GetPremiumDataCard = z.infer<typeof PremiumDataCard>;
