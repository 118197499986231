import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';

export const TempNativeLogSchema = z.object({
	id: z.string().cuid(),
	createdAt: z.coerce.date().nullable(),
	user: z.string().nullable(),
	device: z.string().nullable(),
	message: z.string().nullable(),
	relationId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.TempNativeLog>;
