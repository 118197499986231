const isValidBankAccount = (accNumber: string): boolean => {
	const regex = /^(?:([0-9]{1,6})-)?([0-9]{2,10})\/([0-9]{4})$/;
	const matches = accNumber.match(regex);

	if (!matches) {
		return false;
	}

	const weights: number[] = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
	const prefix = matches[1] ? matches[1].padStart(6, '0') : '000000';
	const main = matches[2].padStart(10, '0');

	// Check prefix
	let checkSum = 0;
	for (let i = 0; i < prefix.length; i += 1) {
		checkSum += weights[i] * parseInt(prefix[i], 10);
	}
	if (checkSum % 11 !== 0) {
		return false;
	}

	// Check main part
	checkSum = 0;
	for (let i = 0; i < main.length; i += 1) {
		checkSum += weights[i] * parseInt(main[i], 10);
	}
	return checkSum % 11 === 0;
};

export default isValidBankAccount;
