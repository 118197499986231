import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';

const TaskStateSchema = z.enum(['complete', 'inProgress', 'new']) satisfies z.ZodType<PrismaTypes.TaskState>;

export const TimeSchema = z
	.object({
		id: z.string().cuid(),
		start: z.coerce.date({
			errorMap: (issue, { defaultError }) => ({
				message: issue.code === 'invalid_date' ? 'Pole je povinné.' : defaultError,
			}),
		}),
		end: z.coerce.date().nullable(),
		employeeId: z
			.string()
			.nullable()
			.refine((data) => Boolean(data), { params: { i18n: 'employeeId' } }),
		taskId: z
			.string()
			.nullable()
			.refine((data) => Boolean(data), { params: { i18n: 'taskId' } }),
		hourlyRate: z.instanceof(PrismaTypes.Prisma.Decimal).nullable(),
		coordinateId: z.string().nullable(),
	})
	.extend({
		task: z
			.object({
				id: z.string().nullable(),
				name: z.string().nullable(),
				typeOfTask: z.string().nullable(),
				state: TaskStateSchema.nullable().optional(),
				job: z
					.object({
						title: z.string().nullable(),
						id: z.string().nullable(),
					})
					.nullable(),
			})
			.nullish(),
		deletedAt: z.coerce.date().nullable(),
	}) satisfies z.ZodType<PrismaTypes.Time>;

export const TimeWithRelationsSchema = TimeSchema.extend({
	task: z
		.object({
			id: z.string().nullable(),
			name: z.string().nullable(),
			typeOfTask: z.string().nullable(),
			job: z
				.object({
					title: z.string().nullable(),
					id: z.string().nullable(),
				})
				.nullable(),
		})
		.nullish(),
	deletedAt: z.coerce.date().nullable(),
	employee: z
		.object({
			id: z.string().nullable(),
			firstName: z.string().nullable(),
			surName: z.string().nullable(),
			hourlyRate: z.number().nullable(),
			image: z.string().nullable(),
			jobTitle: z.string().nullable(),
		})
		.nullable()
		.optional(),
});

export const TimePageSchema = z.object({
	times: z.array(TimeWithRelationsSchema),
	records: z.array(TimeWithRelationsSchema).nullish(),
	nextCursor: z.string(),
});

export type Time = z.infer<typeof TimeSchema>;
export type TimeWithRelations = z.infer<typeof TimeWithRelationsSchema>;

export type TimePage = z.infer<typeof TimePageSchema>;

export const TimeWithBreakSchema = TimeSchema.extend({
	isBreak: z.boolean().nullish(),
	task: z
		.object({
			name: z.string().nullable(),
			typeOfTask: z.string().nullable(),
			job: z
				.object({
					id: z.string().nullable(),
					title: z.string().nullable(),
				})
				.nullable(),
		})
		.nullable(),
});

export type TimeWithBreak = z.infer<typeof TimeWithBreakSchema>;
