import { z } from 'zod';

export const WhisperAddressSchema = z.object({
	name: z.string(),
	label: z.string(),
	postalCode: z.string(),
	number: z.string(),
	street: z.string(),
	city: z.string(),
	country: z.string(),
});

export type WhisperAddress = z.infer<typeof WhisperAddressSchema>;

export const GetWhisperAddressParamsQuery = z.object({
	q: z.string(),
});

export type GetWhisperAddressParams = z.infer<typeof GetWhisperAddressParamsQuery>;
