import { z } from 'zod';
import { NotificationSchema } from './notification';

export const GroupedNotificationTypeSchema = z.enum(['notification', 'invitation', 'request']);
export type GroupedNotificationType = z.infer<typeof GroupedNotificationTypeSchema>;

export const GroupedNotificationDataSchema = z.object({
	id: z.string().cuid(),
	type: GroupedNotificationTypeSchema,
	createdAt: z.coerce.date().nullable(),
	image: z.string().nullable(),
	noImageText: z.string().nullable(),
	senderName: z.string().nullable(),
	receiverName: z.string().nullable(),
	isRead: z.boolean(),
	notification: NotificationSchema.pick({
		content: true,
		infoContent: true,
		type: true,
	}).optional(),
});

export const GroupedNotificationSchema = z.object({
	newer: GroupedNotificationDataSchema.array(),
	older: GroupedNotificationDataSchema.array(),
});

export type GroupedNotificationData = z.infer<typeof GroupedNotificationDataSchema>;
export type GroupedNotification = z.infer<typeof GroupedNotificationSchema>;
