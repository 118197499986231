import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';

export const ContactPersonSchema = z.object({
	id: z.string(),
	titleBefore: z.string().nullable(),
	firstName: z.string(),
	surName: z.string(),
	titleAfter: z.string().nullable(),
	phoneNumber: z.string().nullable(),
	email: z.string().email().nullable(),
	position: z.string().nullable(),
	customerId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.ContactPerson>;

export type ContactPerson = z.infer<typeof ContactPersonSchema>;
