import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { UserSchema } from '../prisma/user';

export const UpdateUserSchema = UserSchema.omit({
	id: true,
	updateAt: true,
	createAt: true,
	deletedAt: true,
}) satisfies z.ZodType<Prisma.UserUpdateInput>;

export type UpdateUser = z.infer<typeof UpdateUserSchema>;

export const LoginUserSchema = UserSchema.pick({
	email: true,
	password: true,
}) satisfies z.ZodType<Prisma.UserCreateInput>;

export type LoginUser = z.infer<typeof LoginUserSchema>;

export const CreateUserSchema = UserSchema.pick({
	name: true,
	email: true,
	password: true,
}).extend({
	name: z.string().refine(
		(value) =>
			// Validates names with Latin/accented chars, allows apostrophes/carons between letters, and optional spaces between name parts
			/^(?:[A-Za-zÀ-ÿ\u0100-\u017F]+(?:['ˇ][A-Za-zÀ-ÿ\u0100-\u017F]+)*)(?:\s+(?:[A-Za-zÀ-ÿ\u0100-\u017F]+(?:['ˇ][A-Za-zÀ-ÿ\u0100-\u017F]+)*)?)*$/.test(
				value,
			),
		{
			params: { i18n: 'choseRealName' },
		},
	),
}) satisfies z.ZodType<Prisma.UserCreateInput>;

export type CreateUser = z.infer<typeof CreateUserSchema>;

export type User = z.infer<typeof UserSchema>;
export const NullableUserSchema = UserSchema.nullable();
export type NullableUser = z.infer<typeof NullableUserSchema>;

export const FindUserQuerySchema = z.object({
	where: z
		.object({
			id: z.string().optional(),
		})
		.optional(),
}) satisfies z.ZodType<Prisma.UserFindManyArgs>;

export type FindUserQuery = z.infer<typeof FindUserQuerySchema>;

export const GetUsersParamsQuery = z.object({
	q: FindUserQuerySchema.optional(),
});
export type GetUsersParams = z.infer<typeof GetUsersParamsQuery>;

export const ResetPasswordRequestSchema = z.object({
	token: z.string(),
	password: z.string(),
});

export type ResetPasswordRequest = z.infer<typeof ResetPasswordRequestSchema>;

export const ResetPasswordOnlySchema = ResetPasswordRequestSchema.omit({ token: true });
export type ResetPasswordOnly = z.infer<typeof ResetPasswordOnlySchema>;

export const ResetPasswordResponseSchema = z.object({ foo: z.string().optional() });
export type ResetPasswordResponse = z.infer<typeof ResetPasswordResponseSchema>;

export const RequestResetPasswordRequestSchema = z.object({
	email: z.string().email(),
});

export type RequestResetPasswordRequest = z.infer<typeof RequestResetPasswordRequestSchema>;
export const RequestResetPasswordResponseSchema = z.object({ foo: z.string().optional() });
export type RequestResetPasswordResponse = z.infer<typeof RequestResetPasswordResponseSchema>;

export const ChangePasswordRequestSchema = z.object({
	passwordConfirm: z.string(),
	password: z.string(),
});

export type ChangePasswordRequest = z.infer<typeof ChangePasswordRequestSchema>;

export const DeletedActualLoggedUserParamsQuery = z.object({
	token: z.string(),
});

export type DeletedActualLoggedUserParams = z.infer<typeof DeletedActualLoggedUserParamsQuery>;
