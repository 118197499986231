import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { TempNativeLogSchema } from '../prisma/tempNativeLog';

export const CreateTempNativeLogSchema = TempNativeLogSchema.pick({
	user: true,
	device: true,
	message: true,
	relationId: true,
}) satisfies z.ZodType<Prisma.TempNativeLogCreateInput>;

export type CreateTempNativeLog = z.infer<typeof CreateTempNativeLogSchema>;
