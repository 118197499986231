import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { AddressSchema } from '../prisma/address';

export const UpdateAddressSchema = AddressSchema.omit({
	id: true,
	employeeId: true,
	customerId: true,
	ownerId: true,
	deletedAt: true,
}).extend({
	postalCode: z.number().nullish(),
}) satisfies z.ZodType<Prisma.AddressUpdateInput>;

export type UpdateAddress = z.infer<typeof UpdateAddressSchema>;

export const CreateAddressSchema = AddressSchema.omit({
	id: true,
	deletedAt: true,
}).extend({
	postalCode: z.number().nullish(),
}) satisfies z.ZodType<Prisma.AddressCreateInput>;

export type CreateAddress = z.infer<typeof CreateAddressSchema>;

export const ImportAddressSchema = AddressSchema.omit({
	id: true,
	employeeId: true,
	customerId: true,
	ownerId: true,
	deletedAt: true,
	note: true,
}).extend({
	postalCode: z.number().nullish(),
}) satisfies z.ZodType<Prisma.AddressCreateInput>;

export type ImportAddress = z.infer<typeof ImportAddressSchema>;

export const AllowedAddressesTypeSchema = z.object({
	label: z.string(),
	value: z.string(),
});

export type AllowedAddressesType = z.infer<typeof AllowedAddressesTypeSchema>;

export const FindAddressesQuerySchema = z.object({
	where: z
		.object({
			employeeId: z.string().cuid().optional().nullish(),
			customerId: z.string().cuid().optional().nullish(),
			ownerId: z.string().cuid().optional().nullish(),
			customer: z
				.object({
					ownerId: z.string().cuid().optional().nullish(),
				})
				.optional()
				.nullish(),
		})
		.optional(),
}) satisfies z.ZodType<Prisma.AddressFindManyArgs>;

export type FindAddressesQuery = z.infer<typeof FindAddressesQuerySchema>;

export const GetAddressesParamsQuery = z.object({
	q: FindAddressesQuerySchema.optional(),
	ownerId: z.string().cuid(),
});

export type GetAddressesParams = z.infer<typeof GetAddressesParamsQuery>;

export const FindAllowedAddressesTypeQuerySchema = z.object({
	where: z.object({
		employeeId: z.string().cuid().optional().nullish(),
		customerId: z.string().cuid().optional().nullish(),
		ownerId: z.string().cuid().optional().nullish(),
	}),
});

export type FindAllowedAddressesTypeQuery = z.infer<typeof FindAllowedAddressesTypeQuerySchema>;

export const GetAllowedAddressesTypesParamsQuery = z.object({
	q: FindAllowedAddressesTypeQuerySchema,
});

export type GetAllowedAddressesTypesParams = z.infer<typeof GetAllowedAddressesTypesParamsQuery>;
