import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';

export const AttachmentSchema = z.object({
	id: z.string().cuid(),
	name: z.string().nullable(),
	fileUrl: z.string().nullable(),
	employeeId: z.string().nullable(),
	jobId: z.string().nullable(),
	taskId: z.string().nullable(),
	commentId: z.string().nullable(),
	customerId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.Attachment>;

export type Attachment = z.infer<typeof AttachmentSchema>;
