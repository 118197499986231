import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';
import { EmployeeSchema } from './employee';
import { UserSchema } from './user';

export const RoleSchema = z.enum(['admin', 'user', 'superAdmin']) satisfies z.ZodType<PrismaTypes.Role>;
export type Role = z.infer<typeof RoleSchema>;

const TableSettingsSchema = z.object({
	sorting: z.any().optional(),
	globalFilter: z.string().optional(),
	columnFilters: z.any().optional(),
	userRoleId: z.string().optional(),
	tableName: z.string().optional(),
});

const DashboardSettingsSchema = z.object({
	userRoleId: z.string().optional(),
	dashboardType: z.string().optional(),
});

const UiSettingsSchema = z.object({
	employeeTable: TableSettingsSchema.optional(),
	jobTable: TableSettingsSchema.optional(),
	dashboard: DashboardSettingsSchema.optional(),
});

export const UserRoleSchema = z.object({
	role: RoleSchema.nullable(),
	id: z.string().cuid(),
	active: z.boolean(),
	userId: z.string().nullable(),
	ownerId: z.string().nullable(),
	employeeId: z.string().nullable(),
	employee: EmployeeSchema.nullish(),
	deletedAt: z.coerce.date().nullable(),
	uiSettings: UiSettingsSchema.nullable(),
}) satisfies z.ZodType<PrismaTypes.UserRole>;

export const UserRoleWithRelationsSchema = UserRoleSchema.extend({
	user: UserSchema.nullish(),
});

export type UserRole = z.infer<typeof UserRoleSchema>;
export type UserRoleWithRelations = z.infer<typeof UserRoleWithRelationsSchema>;
