import { z } from 'zod';
import { QRCodeSchema } from '../prisma/qrCode';

export const CreateQRCodeSchema = QRCodeSchema.omit({
	id: true,
	createdAt: true,
	updatedAt: true,
	validUntil: true,
	deletedAt: true,
});

export type CreateQRCode = z.infer<typeof CreateQRCodeSchema>;

export const QRCodeResponseSchema = QRCodeSchema.pick({
	id: true,
});

export type QRCodeResponse = z.infer<typeof QRCodeResponseSchema>;

export const CreateQRCodeFormSchema = CreateQRCodeSchema.pick({
	location: true,
}).extend({
	pin: z.string().optional(),
});

export type CreateQRCodeForm = z.infer<typeof CreateQRCodeFormSchema>;

// api v2

export const RegisterQrTransmitterRequestSchema = z.object({
	transmitterId: z.string(),
});

export type RegisterQrTransmitterRequest = z.infer<typeof RegisterQrTransmitterRequestSchema>;

export const RegisterQrResponseSchema = z.object({
	privateKey: z.string(),
});

export type RegisterQrResponse = z.infer<typeof RegisterQrResponseSchema>;

export const ConfirmQrCodeScanRequestSchema = z.object({
	qr: z.string(),
});

export type ConfirmQrCodeScanRequest = z.infer<typeof ConfirmQrCodeScanRequestSchema>;
