import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';

export const AddressSchema = z.object({
	id: z.string().cuid(),
	street: z.string().nullable(),
	number: z.string().nullable(),
	postalCode: z.instanceof(PrismaTypes.Prisma.Decimal).nullable(),
	city: z.string().nullable(),
	country: z.string().nullable(),
	note: z.string().nullable(),
	type: z.string().nullable(),
	employeeId: z.string().nullable(),
	customerId: z.string().nullable(),
	ownerId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.Address>;

export type Address = z.infer<typeof AddressSchema>;
