import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { RequestSchema } from '../prisma/request';

export const UpdateRequestSchema = RequestSchema.omit({
	id: true,
	updatedAt: true,
	createdAt: true,
	deletedAt: true,
}).extend({
	state: z.enum(['approved', 'pending', 'rejected']).nullish(),
	approvedDate: z.coerce.date().nullish(),
	approveId: z.string().nullish(),
	applicantId: z.string().nullish(),
	requestType: z.string().nullish(),
	note: z.string().nullish(),
}) satisfies z.ZodType<Prisma.RequestUpdateInput>;

export type UpdateRequest = z.infer<typeof UpdateRequestSchema>;

export const CreateRequestSchema = RequestSchema.omit({
	id: true,
	createdAt: true,
	updatedAt: true,
	deletedAt: true,
}).extend({
	approvedDate: z.coerce.date().nullish(),
	note: z.string().nullish(),
}) satisfies z.ZodType<Prisma.RequestCreateInput>;

export type CreateRequest = z.infer<typeof CreateRequestSchema>;

export const FindRequestsQuerySchema = z.object({
	where: z.object({
		id: z.string().cuid().optional(),
		approveId: z.string().nullish(),
		applicantId: z.string().nullish(),
		applicant: z
			.object({
				ownerId: z.string().cuid().optional(),
			})
			.optional(),
		state: z.enum(['approved', 'pending', 'rejected']).nullish(),
	}),
	include: z
		.object({
			approver: z.boolean().optional(),
			applicant: z.object({
				include: z.object({
					userRoles: z.object({
						include: z.object({
							user: z.object({
								select: z.object({
									image: z.boolean().optional(),
								}),
							}),
						}),
					}),
				}),
			}),
		})
		.nullable()
		.optional(),
}) satisfies z.ZodType<Prisma.RequestFindManyArgs>;

export type FindRequestsQuery = z.infer<typeof FindRequestsQuerySchema>;

export const GetRequestsParamsQuery = z.object({
	q: FindRequestsQuerySchema.optional(),
});

export type GetRequestsParams = z.infer<typeof GetRequestsParamsQuery>;
