import { z } from 'zod';

export const DataSchema = z.object({
	key: z.string(),
	value: z.any(),
	include: z.boolean(),
	isTitle: z.boolean(),
});

export type Data = z.infer<typeof DataSchema>;

export const ResultSchema = z.object({
	id: z.string(),
	href: z.string(),
	data: z.array(DataSchema),
});

export type Result = z.infer<typeof ResultSchema>;

export const NestedResultSchema = ResultSchema;

export type NestedResult = z.infer<typeof NestedResultSchema>;

export const NestedSearchSchema = z.object({
	id: z.string(),
	category: z.string(),
	results: z.array(NestedResultSchema),
});

export type NestedSearch = z.infer<typeof NestedSearchSchema>;

export const SearchResultSchema = ResultSchema.extend({
	nestedSearch: z.array(NestedSearchSchema).optional(),
});

export type SearchResult = z.infer<typeof SearchResultSchema>;

export const SearchSchema = z.object({
	id: z.string(),
	category: z.string(),
	results: z.array(SearchResultSchema),
});

export type Search = z.infer<typeof SearchSchema>;

export const GetSearchResultsParamsQuery = z.object({
	q: z.coerce.string(),
	ownerId: z.string(),
});

export type GetSearchResultsParams = z.infer<typeof GetSearchResultsParamsQuery>;
