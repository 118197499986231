function checkDate(year: number, month: number, day: number): boolean {
	const date = new Date(year, month - 1, day);
	return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}
export const verifyRC = (rc: string): boolean => {
	// be liberal in what you receive
	const regex = /^\s*(\d\d)(\d\d)(\d\d)[ /]*(\d\d\d)(\d?)\s*$/;
	const matches = rc.match(regex);

	if (!matches) {
		return false;
	}

	// eslint-disable-next-line prefer-const
	let [, year, month, day, ext, c] = matches;

	if (c === '') {
		let numericYear = parseInt(year, 10);
		numericYear += numericYear < 54 ? 1900 : 1800;
		year = String(numericYear);
	} else {
		// kontrolní číslice
		let mod = parseInt(year + month + day + ext, 10) % 11;
		const numericC = parseInt(c, 10);
		if (mod === 10) mod = 0;
		if (mod !== numericC) {
			return false;
		}

		let numericYear = parseInt(year, 10);
		numericYear += numericYear < 54 ? 2000 : 1900;
		year = String(numericYear);
	}

	// k měsíci může být připočteno 20, 50 nebo 70
	let numericMonth = parseInt(month, 10);
	const numericYear = parseInt(year, 10);
	if (numericMonth > 70 && numericYear > 2003) {
		numericMonth -= 70;
	} else if (numericMonth > 50) {
		numericMonth -= 50;
	} else if (numericMonth > 20 && numericYear > 2003) {
		numericMonth -= 20;
	}
	month = String(numericMonth);

	// kontrola data
	return checkDate(+year, +month, +day);
};
