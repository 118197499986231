import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { CommentSchema } from '../prisma/comment';

export const UpdateCommentSchema = CommentSchema.omit({
	id: true,
	employeeId: true,
	taskId: true,
	updatedAt: true,
	createdAt: true,
	deletedAt: true,
}) satisfies z.ZodType<Prisma.CommentUpdateInput>;

export type UpdateComment = z.infer<typeof UpdateCommentSchema>;
