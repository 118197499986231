import { z } from 'zod';

export const SendEmailResponseSchema = z.object({
	message: z.string().optional(),
});

export type SendEmailResponse = z.infer<typeof SendEmailResponseSchema>;

export const CreateEmailSchema = z.object({
	name: z.string(),
	email: z.string(),
	phoneNumber: z.string().optional(),
	message: z.string(),
});

export type CreateEmail = z.infer<typeof CreateEmailSchema>;
