import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { TaskSchema, TaskStateSchema } from '../prisma/task';
import { TimeSchema } from '../prisma/time';
import { CustomerSchema } from '../prisma/customer';
import { AddressSchema } from '../prisma/address';

export const UpdateTaskSchema = TaskSchema.omit({
	id: true,
	updatedAt: true,
	createdAt: true,
	deletedAt: true,
}).extend({
	// toto prepisuje ten debilni decimal typ ktery nejde vypnout
	priority: z.string().nullish(),
	estimatedTime: z.number().nullish(),
	estimatedPrice: z.number().nullish(),
	jobId: z.string().nullable(),
}) satisfies z.ZodType<Prisma.TaskUpdateInput>;

export const UpdateTasksBatchSchema = z.object({
	tasksIds: z.array(z.string().cuid()),
	newState: TaskStateSchema,
}) satisfies z.ZodType<{ tasksIds: string[]; newState: string }>;

export type UpdateTasksBatch = z.infer<typeof UpdateTasksBatchSchema>;

export const DetailTaskSchema = TaskSchema.extend({
	priority: z.number().nullish(),
	estimatedTime: z.number().nullish(),
	jobId: z.string().nullable(),
});

export type DetailTask = z.infer<typeof DetailTaskSchema>;

export type UpdateTask = z.infer<typeof UpdateTaskSchema>;

export const CreateTaskSchema = TaskSchema.omit({
	id: true,
	updatedAt: true,
	createdAt: true,
	deletedAt: true,
}).extend({
	// toto prepisuje ten debilni decimal typ ktery nejde vypnout
	priority: z.string().nullish(),
	estimatedTime: z.number().nullish(),
	estimatedPrice: z.number().nullish(),
}) satisfies z.ZodType<Prisma.TaskCreateInput>;

export type CreateTask = z.infer<typeof CreateTaskSchema>;

export const FindTaskQuerySchema = z.object({
	where: z
		.object({
			id: z.string().cuid().optional(),
			employeeId: z.string().cuid().optional().nullish(),
			state: TaskStateSchema.nullish(),
			jobId: z.string().cuid().optional(),
			job: z
				.object({
					ownerId: z.string().cuid().optional(),
				})
				.optional(),
			employee: z
				.object({
					email: z.string().optional(),
				})
				.optional(),
		})
		.optional(),
	include: z
		.object({
			employee: z.boolean().optional(),
			job: z.boolean().optional(),
		})
		.optional(),
}) satisfies z.ZodType<Prisma.TaskFindManyArgs>;

export type FindTaskQuery = z.infer<typeof FindTaskQuerySchema>;

export const GetTasksParamsQuery = z.object({
	q: FindTaskQuerySchema.optional(),
});

export type GetTasksParams = z.infer<typeof GetTasksParamsQuery>;

export const GetTasksStatsParamsQuery = z.object({
	q: z.object({
		where: z.object({
			jobId: z.string().cuid(),
		}),
	}),
	ownerId: z.string(),
});

export type GetTasksStatsParams = z.infer<typeof GetTasksStatsParamsQuery>;

export const GetTasksStatsSchema = z.object({
	totalTasks: z.number(),
	finishedTasks: z.number(),
	finishedToday: z.number(),
	finishedPercent: z.number(),
});

export type GetTasksStats = z.infer<typeof GetTasksStatsSchema>;

export const GetTasksTimeStatsParamsQuery = z.object({
	q: z.object({
		where: z.object({
			jobId: z.string().cuid().optional(),
			taskId: z.string().cuid().optional(),
		}),
	}),
	ownerId: z.string(),
});

export type GetTasksTimeStatsParams = z.infer<typeof GetTasksTimeStatsParamsQuery>;

export const GetTasksTimeStatsSchema = z.object({
	totalTime: z.string(),
	estimatedTime: z.string(),
	timeToday: z.string(),
	timePercent: z.number(),
	entriesWithoutEnd: TimeSchema.array(),
});

export type GetTasksTimeStats = z.infer<typeof GetTasksTimeStatsSchema>;

export const GetTimeTasksStatsParamsQuery = z.object({
	q: z.object({
		where: z.object({
			taskId: z.string().cuid().optional(),
		}),
	}),
	ownerId: z.string(),
});

export type GetTimeTasksStatsParams = z.infer<typeof GetTimeTasksStatsParamsQuery>;

export const GetTimeTasksStatsSchema = z.object({
	totalTasks: z.number(),
	finishedTasks: z.number(),
	finishedToday: z.number(),
	finishedPercent: z.number(),
});

export type GetTimeTasksStats = z.infer<typeof GetTimeTasksStatsSchema>;

export const GetTasksCostsStatsParamsQuery = z.object({
	q: z.object({
		where: z.object({
			jobId: z.string().cuid().optional(),
			taskId: z.string().cuid().optional(),
		}),
	}),
	ownerId: z.string(),
});

export type GetTasksCostsStatsParams = z.infer<typeof GetTasksCostsStatsParamsQuery>;

export const GetTasksCostsStatsSchema = z.object({
	totalCost: z.string(),
	estimatedCost: z.string(),
	costsToday: z.string(),
	costsPercent: z.number(),
	entriesWithoutEnd: TimeSchema.array(),
});

export type GetTasksCostsStats = z.infer<typeof GetTasksCostsStatsSchema>;

export const GetTaskDetailsParamsQuery = z.object({
	ownerId: z.string(),
	q: z.object({
		where: z.object({
			taskId: z.string(),
		}),
	}),
});

export type GetTaskDetailsParams = z.infer<typeof GetTaskDetailsParamsQuery>;

export const GetTaskDetailsSchema = z.object({
	customer: CustomerSchema.extend({
		addresses: AddressSchema.array(),
	}).nullable(),
	task: z
		.object({
			id: z.string().nullable(),
			name: z.string().nullable(),
			job: z.object({
				id: z.string().nullable(),
				name: z.string().nullable(),
			}),
			state: z.string().nullable().optional(),
			typeOfTask: z.string().nullable(),
			createdAt: z.coerce.date().nullable(),
			endDate: z.string().nullable(),
			priority: z.number().nullable(),
			employee: z.object({
				id: z.string().nullable(),
				firstName: z.string().nullable(),
				surName: z.string().nullable(),
			}),
			description: z.string().nullable(),
		})
		.nullable(),
});

export type GetTaskDetails = z.infer<typeof GetTaskDetailsSchema>;
