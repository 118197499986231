import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';

export const CoordinateSchema = z.object({
	id: z.string().cuid(),
	userId: z.string().nullable(),
	ownerId: z.string(),
	latitude: z.instanceof(PrismaTypes.Prisma.Decimal),
	longitude: z.instanceof(PrismaTypes.Prisma.Decimal),
	accuracy: z.instanceof(PrismaTypes.Prisma.Decimal).nullable(),
	speed: z.instanceof(PrismaTypes.Prisma.Decimal).nullable(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date().nullable(),
	timeId: z.string().nullable(),
	workScheduleId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.Coordinate>;

export type Coordinate = z.infer<typeof CoordinateSchema>;
