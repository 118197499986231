import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';

export const QRCodeSchema = z.object({
	id: z.string().cuid(),
	sessionId: z.string().uuid(),
	createdAt: z.coerce.date().nullable(),
	updatedAt: z.coerce.date().nullable(),
	validUntil: z.coerce.date().nullable(),
	location: z.string().nullable(),
	ownerId: z.string(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.QRCode>;

export type QRCode = z.infer<typeof QRCodeSchema>;
