import { z } from 'zod';

export const ErrorPayloadSchema = z.object({
	message: z.string().optional(),
	status: z.number().optional(),
});

export const PayloadSchema = z.object({
	message: z.string(),
});

// todo nevim jak to bude vypadat
export type ErrorPayload = z.infer<typeof ErrorPayloadSchema>;

export type Payload = z.infer<typeof PayloadSchema> | void;

export function createCursorPaginatedResponseSchema<ItemType extends z.ZodTypeAny>(itemSchema: ItemType) {
	return z.object({
		nextCursor: z.string().optional(),
		records: z.array(itemSchema),
		itemCount: z.number().optional(),
	});
}

export const CursorPaginatedRequestQuerySchema = z.object({
	cursor: z.string().optional(),
	take: z.coerce.number().optional(),
});
