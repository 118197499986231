import { z } from 'zod';
import { CoordinateSchema } from '../prisma/coordinate';

export const CreateCoordinateSchema = CoordinateSchema.omit({
	id: true,
	userId: true,
	ownerId: true,
	updatedAt: true,
	createdAt: true,
	deletedAt: true,
}).extend({
	latitude: z.number(),
	longitude: z.number(),
	accuracy: z.number().nullable(),
	speed: z.number().nullable(),
});

export type CreateCoordinate = z.infer<typeof CreateCoordinateSchema>;

export const CoordinateExtendSchema = CoordinateSchema.extend({
	latitude: z.number(),
	longitude: z.number(),
	accuracy: z.number().nullable(),
	speed: z.number().nullable(),
	user: z.object({
		userRoles: z.array(
			z.object({
				employee: z.object({
					firstName: z.string(),
					surName: z.string(),
					id: z.string(),
				}),
				employeeId: z.string(),
			}),
		),
	}),
	time: z.object({
		task: z.object({
			id: z.string(),
			name: z.string(),
			job: z.object({
				id: z.string(),
				name: z.string(),
			}),
		}),
	}),
});

export type CoordinateExtend = z.infer<typeof CoordinateExtendSchema>;

export const GetCoordinatesParamsQuery = z.object({
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
	jobIds: z.array(z.string()).nullable().optional(),
	userIds: z.array(z.string()).nullable().optional(),
	taskIds: z.array(z.string()).nullable().optional(),
	employeeIds: z.array(z.string()).nullable().optional(),
});

export type GetCoordinatesParams = z.infer<typeof GetCoordinatesParamsQuery>;

export const GetLastCoordinatesParamsQuery = z.object({
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
	jobIds: z.array(z.string()).nullable().optional(),
	userIds: z.array(z.string()).nullable().optional(),
	taskIds: z.array(z.string()).nullable().optional(),
	employeeIds: z.array(z.string()).nullable().optional(),
});

export type GetLastCoordinatesParams = z.infer<typeof GetLastCoordinatesParamsQuery>;
