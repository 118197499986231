import * as z from 'zod';
import type { Prisma } from '@prisma/client';
import { NotificationSchema } from '../prisma/notification';

export const UpdateNotificationSchema = NotificationSchema.omit({
	id: true,
	type: true,
	entityType: true,
	entityId: true,
	infoContent: true,
	content: true,
	createdAt: true,
	updatedAt: true,
	userId: true,
	createdUser: true,
	ownerId: true,
	deletedAt: true,
}).extend({
	isRead: z.boolean().optional(),
}) satisfies z.ZodType<Prisma.NotificationUpdateInput>;

export type UpdateNotification = z.infer<typeof UpdateNotificationSchema>;

export const CreateNotificationSchema = NotificationSchema.omit({
	id: true,
	createdAt: true,
	updatedAt: true,
	deletedAt: true,
}) satisfies z.ZodType<Prisma.NotificationCreateInput>;

export type CreateNotification = z.infer<typeof CreateNotificationSchema>;

export const FindNotificationsQuerySchema = z.object({
	where: z.object({
		id: z.string().cuid().optional(),
		type: z.enum(['info', 'warning', 'error']).optional(),
		entityType: z.enum(['time', 'task', 'job']).optional(),
		entityId: z.string().optional(),
		infoContent: z.string().optional(),
		content: z.string().optional(),
		createdAt: z.date().optional(),
		updatedAt: z.date().optional(),
		isRead: z.boolean().optional(),
		userId: z.string().optional(),
		createdUser: z.string().optional(),
		ownerId: z.string().optional(),
	}),
	include: z
		.object({
			user: z.boolean().optional(),
		})
		.nullable()
		.optional(),
}) satisfies z.ZodType<Prisma.NotificationFindManyArgs>;

export type FindNotificationsQuery = z.infer<typeof FindNotificationsQuerySchema>;

export const GetNotificationsParamsQuery = z.object({
	q: FindNotificationsQuerySchema.optional(),
});

export type GetNotificationsParams = z.infer<typeof GetNotificationsParamsQuery>;
