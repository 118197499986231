import { z } from 'zod';

import { isValidImage } from '../validations/image';

export const UploadAvatarSchema = z.object({
	file: z.custom<File>(isValidImage(4 * 1024 * 1024, ['image/jpeg', 'image/png', 'image/webp']), {
		message: 'Invalid file',
	}),
	ownerId: z.string(),
	employeeId: z.string(),
});

export type UploadAvatar = FormData & {
	file?: File;
	ownerId?: string;
	employeeId?: string;
};
