import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import * as PrismaTypes from '@prisma/client';
import { OwnerSchema } from '../prisma/owner';
import { CreateBankAccountSchema } from './bankAccount';
import { CreateAddressSchema } from './addresses';

export const CreateOwnerSchema = OwnerSchema.omit({
	id: true,
	deletedAt: true,
	updatedAt: true,
	createdAt: true,
	stripeCustomerId: true,
	stripeSubscriptionId: true,
	trialEndDate: true,
	employeesPaidFor: true,
	subscriptionCancelledAt: true,
	subscriptionIsAutoRenew: true,
	subscriptionPeriodEnd: true,
	subscriptionPeriodStart: true,
	subscriptionActiveFrom: true,
}).extend({
	bankAccountsToCreate: z.array(CreateBankAccountSchema).optional(),
	addressesToCreate: z.array(CreateAddressSchema).optional(),
}) satisfies z.ZodType<Prisma.OwnerCreateInput>;

export type CreateOwner = z.infer<typeof CreateOwnerSchema>;

export const UpdateOwnerSchema = OwnerSchema.omit({
	id: true,
	deletedAt: true,
	stripeCustomerId: true,
	stripeSubscriptionId: true,
	trialEndDate: true,
	employeesPaidFor: true,
}) satisfies z.ZodType<Prisma.OwnerUpdateInput>;

export type UpdateOwner = z.infer<typeof UpdateOwnerSchema>;

export const OwnerWithRolesSchema = OwnerSchema.extend({
	isActive: z.boolean(),
	userRole: z.enum(['admin', 'user', 'superAdmin']).nullable(),
	employeeId: z.string().nullable(),
	id: z.string().cuid().optional(),
	companyName: z.string().nullable().optional(),
	color: z.string().nullable().optional(),
	deletedAt: z.date().nullable().optional(),
	stripeCustomerId: z.string().nullable().optional(),
	stripeSubscriptionId: z.string().nullable().optional(),
	trialEndDate: z.date().nullable().optional(),
	employeesPaidFor: z.instanceof(PrismaTypes.Prisma.Decimal).nullable().optional(),
	subscriptionIsAutoRenew: z.boolean().nullable().optional(),
	subscriptionPeriodEnd: z.date().nullable().optional(),
	subscriptionPeriodStart: z.date().nullable().optional(),
	subscriptionCancelledAt: z.date().nullable().optional(),
	subscriptionActiveFrom: z.date().nullable().optional(),
});

export type OwnerWithRoles = z.infer<typeof OwnerWithRolesSchema>;

export const OwnerCardStatsSchema = z.object({
	employeesCount: z.number(),
	jobsCount: z.number(),
	tasksCount: z.number(),
});

export type OwnerCardStats = z.infer<typeof OwnerCardStatsSchema>;

export const EmployeesCountSchema = z.object({
	employeesCount: z.number(),
});

export type EmployeesCount = z.infer<typeof EmployeesCountSchema>;
