import { z } from 'zod';
import type { Prisma } from '@prisma/client';

import { BankAccountSchema } from '../prisma/bankAccount';

export const UpdateBankAccountSchema = BankAccountSchema.omit({
	id: true,
	customerId: true,
	createdAt: true,
	updatedAt: true,
	deletedAt: true,
}) satisfies z.ZodType<Prisma.BankAccountUpdateInput>;

export type UpdateBankAccount = z.infer<typeof UpdateBankAccountSchema>;

export const CreateBankAccountSchema = BankAccountSchema.omit({
	id: true,
	createdAt: true,
	updatedAt: true,
	deletedAt: true,
}) satisfies z.ZodType<Prisma.BankAccountCreateInput>;

export type CreateBankAccount = z.infer<typeof CreateBankAccountSchema>;

export const FindBankAccountsQuerySchema = z.object({
	where: z.object({
		id: z.string().cuid().optional(),
		customerId: z.string().cuid().optional(),
		ownerId: z.string().cuid().optional(),
	}),
}) satisfies z.ZodType<Prisma.BankAccountFindManyArgs>;

export type FindBankAccountQuery = z.infer<typeof FindBankAccountsQuerySchema>;

export const GetBankAccountsParamsQuery = z.object({
	q: FindBankAccountsQuerySchema.optional(),
	ownerId: z.string().cuid(),
});
export type GetBankAccountsParams = z.infer<typeof GetBankAccountsParamsQuery>;
