import { z } from 'zod';

export const AresResponseSchema = z
	.object({
		obchodniJmeno: z.string(),
		dic: z.string(),
		personType: z.enum(['selfEmployed', 'legalEntity']).nullish(),
		address: z.object({
			number: z.string().nullable(),
			customerId: z.string().nullable(),
			street: z.string().nullable(),
			city: z.string().nullable(),
			country: z.string().nullable(),
			note: z.string().nullable(),
			employeeId: z.string().nullable(),
			ownerId: z.string().nullable(),
			postalCode: z.number().nullable().optional(),
			type: z.string().nullable(),
		}),
	})
	.partial();

export type AresResponse = z.infer<typeof AresResponseSchema>;

export const IcoRequestSchema = z.object({
	ico: z.string(),
});

export type IcoRequest = z.infer<typeof IcoRequestSchema>;
