import { z } from 'zod';

export const StripeCheckoutResponseSchema = z
	.object({
		url: z.string(),
	})
	.partial();

export type StripeCheckoutResponse = z.infer<typeof StripeCheckoutResponseSchema>;

export const StripeCheckoutRequestSchema = z.object({
	numberOfEmployees: z.number(),
	period: z.string(),
	ownerId: z.string(),
});

export type StripeCheckoutRequest = z.infer<typeof StripeCheckoutRequestSchema>;

export const StripePortalResponseSchema = z
	.object({
		url: z.string(),
	})
	.partial();

export type StripePortalResponse = z.infer<typeof StripePortalResponseSchema>;

export const StripePortalRequestSchema = z.object({
	ownerId: z.string(),
});

export type StripePortalRequest = z.infer<typeof StripePortalRequestSchema>;

export const StripeAddEmployeeResponseSchema = z
	.object({
		message: z.string(),
	})
	.partial();

export type StripeAddEmployeeResponse = z.infer<typeof StripeAddEmployeeResponseSchema>;

export const StripeAddEmployeeRequestSchema = z.object({
	ownerId: z.string(),
});

export type StripeAddEmployeeRequest = z.infer<typeof StripeAddEmployeeRequestSchema>;
