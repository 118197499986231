import { z } from 'zod';

export const AuthTokenRequestSchema = z.object({
	email: z.string(),
	password: z.string(),
});

export type AuthTokenRequest = z.infer<typeof AuthTokenRequestSchema> | void;

export const AuthTokenResponseSchema = z.object({
	access_token: z.string(),
	refresh_token: z.string(),
	scope: z.string(),
	id_token: z.string(),
	token_type: z.string(),
	expires_in: z.number(),
});

export type AuthTokenResponse = z.infer<typeof AuthTokenResponseSchema> | void;

export const RevokeTokenRequestSchema = z.object({ foo: z.string().optional() });

export type RevokeTokenRequest = z.infer<typeof RevokeTokenRequestSchema> | void;

export const RevokeTokenResponseSchema = z.object({ foo: z.string().optional() });

export type RevokeTokenResponse = z.infer<typeof RevokeTokenResponseSchema> | void;
