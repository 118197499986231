import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';
import { RoleSchema } from './userRole';
import { OwnerSchema } from './owner';

export const InvitationStateSchema = z.enum([
	'approved',
	'rejected',
	'pending',
]) satisfies z.ZodType<PrismaTypes.InvitationState>;
export type InvitationState = z.infer<typeof InvitationStateSchema>;
export const InvitationSchema = z.object({
	id: z.string().cuid(),
	createdAt: z.coerce.date().nullable(),
	updatedAt: z.coerce.date().nullable(),
	email: z.string().email().nullable(),
	employeeId: z.string().nullable(),
	ownerId: z.string().nullable(),
	owner: OwnerSchema.nullable(),
	role: RoleSchema.nullable(),
	expires: z.coerce.date().nullable(),
	invitedById: z.string().nullable(),
	state: InvitationStateSchema.nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.Invitation>;

export type Invitation = z.infer<typeof InvitationSchema>;
