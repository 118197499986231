import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { InvitationSchema, InvitationStateSchema } from '../prisma/invitation';

export const UpdateInvitationSchema = InvitationSchema.omit({
	id: true,
	updatedAt: true,
	createdAt: true,
	deletedAt: true,
	owner: true,
}).extend({
	employeeId: z.string().cuid().optional(),
	ownerId: z.string().cuid().optional(),
	invitedById: z.string().cuid().optional(),
	email: z.string().email().optional(),
	role: z.enum(['user', 'admin', 'superAdmin']).optional(),
	expires: z.date().optional(),
}) satisfies z.ZodType<Prisma.InvitationUpdateInput>;

export type UpdateInvitation = z.infer<typeof UpdateInvitationSchema>;

export const CreateInvitationSchema = InvitationSchema.omit({
	id: true,
	createdAt: true,
	updatedAt: true,
	deletedAt: true,
	owner: true,
}) satisfies z.ZodType<Prisma.InvitationCreateInput>;

export type CreateInvitation = z.infer<typeof CreateInvitationSchema>;

export const FindInvitationsQuerySchema = z.object({
	where: z.object({
		id: z.string().cuid().optional(),
		ownerId: z.string().cuid().optional(),
		employeeId: z.string().cuid().optional().nullable(),
		email: z.string().email().optional(),
		invitedById: z.string().cuid().optional(),
		state: InvitationStateSchema.optional(),
	}),
}) satisfies z.ZodType<Prisma.InvitationFindManyArgs>;

export type FindInvitationsQuery = z.infer<typeof FindInvitationsQuerySchema>;

export const GetInvitationsParamsQuery = z.object({
	q: FindInvitationsQuerySchema.optional(),
});

export type GetInvitationsParams = z.infer<typeof GetInvitationsParamsQuery>;
