import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';

export const CommentSchema = z.object({
	id: z.string().cuid(),
	text: z.string().nullable(),
	createdAt: z.coerce.date().nullable(),
	updatedAt: z.coerce.date().nullable(),
	taskId: z.string().nullable(),
	employeeId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.Comment>;

export type Comment = z.infer<typeof CommentSchema>;
